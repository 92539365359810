import { DocumentType, MemoType } from '@innedit/innedit-type';
import { MemoData } from 'packages/innedit';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '~/containers/Espace/List/props';
import IconEye from '~/icons/Eye';
import IconEyeSlash from '~/icons/EyeSlash';
import nextPhoto from '~/images/next-photo.svg';

import ListItem from './index';

const ListItemMemo: FC<ListItemProps<MemoType, MemoData>> = ({
  docId,
  index,
  onClick,
  model,
}) => {
  const [doc, setDoc] = useState<DocumentType<MemoType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  const description =
    doc.description && doc.description.length > 250
      ? `${doc.description.slice(0, 250)}...`
      : doc.description;

  let photo;
  try {
    if (doc.medias && doc.medias.length > 0) {
      const media = doc.medias[0];
      switch (media.type) {
        case 'image/jpeg':
        case 'image/png':
        case 'image/webp': {
          photo = `${media.origin}/${media.pathname}?w=80&h=80&c=max`;
          break;
        }

        case 'image/svg+xml':
        default: {
          photo = `${media.origin}/${media.pathname}`;
          break;
        }
      }
    } else {
      photo = nextPhoto;
    }
  } catch (e) {
    photo = nextPhoto;
  }

  return (
    <ListItem
      actions={[
        {
          icon: doc.hidden ? IconEyeSlash : IconEye,
          label: 'Changer la visibilité',
        },
      ]}
      date={doc.publishedAt}
      description={description}
      displayActionId={false}
      doc={doc}
      icon={
        <figure className="aspect-ratio aspect-ratio--square">
          <img alt="Chargement" className="aspect-ratio__content" src={photo} />
        </figure>
      }
      index={index}
      label={doc.label}
      onClick={onClick}
    />
  );
};

export default ListItemMemo;
